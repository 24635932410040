import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import InternationalTitle from 'components/InternationalTitle'
import Compare from 'components/Compare'
import Banner from 'components/EN/Banner'
import DisplayCard from 'components/EN/DisplayCard'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'

const Mixpanel = (props) => {
  const { data } = props
  const { mixpanelYaml } = data
  const { banner, part2, part3 } = mixpanelYaml

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="SensorsData vs Mixpanel"
        description="SensorsData enables on-premise deployment to ensure data security and offers multi-entities capabilities to support a richer range of analysis for different business scenarios, and offer E2E customer professional services to their clients when comparing against Mixpanel"
        keywords="Sensors Data，Sensors Analytics，Mixpanel"
      />
      <div className={styles.mixpanelWrapper}>
        <Banner {...banner} />
        <div className={styles.title}>
          {isMb ? <InternationalTitle title={part2.titleMb} /> : <InternationalTitle title={part2.title} />}
        </div>
        <div className={styles.compareCom}>
          <Compare
            sensorsDataConfig={part2.sensorsDataConfig}
            compareCompany={part2.compareCompany}
            compareContent={part2.compareContent}
          />
        </div>
        <div className={styles.part3}>
          <DisplayCard dataSource={part3} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    mixpanelYaml {
      banner {
        title
        desc
        btn_text
        btn_href
      }
      part2 {
        title
        titleMb
        sensorsDataConfig {
          useIcon
          icon {
            publicURL
          }
        }
        compareCompany {
          name
          useIcon
          icon {
            publicURL
          }
        }
        compareContent {
          content
          SensorsData
          compareCompany
        }
      }
      part3 {
        title
        icon {
          publicURL
        }
        desc
        btn_text
        btn_href
        reverse
        img_url
      }
    }
  }
`

export default Mixpanel
